import { ITelemetryItem } from '@microsoft/applicationinsights-web';
import { AuthHelper } from '@shared/auth/AuthHelper';
import { getSpartaApiUrls } from '@shared/spartaApiPicker';
import * as guid from '@shared/utilities/guid';

import { Dictionary } from '../interfaces/Dictionary';
import { getAssignedRolesFromTestHooks, getEnvironmentIdFromTestHooks, getTenantId, getTenantName } from '../stores/AppStore/environmentUtils';
import { AZUREROLES_HEADER, DFP_ASSIGNEDROLES_HEADER, DFP_CORRID_HEADER, DFP_ENVID_HEADER, DFP_TENANTID_HEADER } from './constants';
import { TelemetryId } from './telemetryConstants';

const sasTokenRegex = /(-write|-read)(.*)/i;

export const scrubSasToken = (str: string): string => {
	if (!sasTokenRegex.test(str)) {
		return str;
	}
	// Get the SAS token in the second regex group
	const sasToken = str.match(sasTokenRegex)[2];

	return str.replace(sasToken, '{sasToken}');
};

const getHtmlElementProperties = (target: HTMLElement): Dictionary<string> => {
	if (!target) {
		return null;
	}

	return {
		targetId: target.id,
		targetName: target.dataset.name ? target.dataset.name : null,
		targetClass: target.className ? target.className : null,
	};
};

export const getEventProperties = (telemetryId: TelemetryId | string, target: HTMLElement): Dictionary<string> => {
	return {
		name: telemetryId,
		...getHtmlElementProperties(target),
	};
};

const removeBaseUrl = (path: string, tenant: string, environmentId: string) => {
	const baseUrl = `${tenant}/env/${environmentId}`;
	const toRemove = path.endsWith(baseUrl) ? baseUrl : `${baseUrl}/`;

	return path.replace(toRemove, '');
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const scrubSensitiveDataFromBaseData = (baseData: Record<string, any>, tenantId: string, tenantName: string, environmentId: string) => {
	const envId = environmentId ?? 'none';
	if (baseData.uri) {
		// Page views don't need base URL
		if (tenantId) {
			baseData.uri = removeBaseUrl(baseData.uri, tenantId, envId);
		}
		if (tenantName) {
			baseData.uri = removeBaseUrl(baseData.uri, tenantName, envId);
		}
	}

	if (baseData.refUri) {
		// Page views don't need base URL
		if (tenantId) {
			baseData.refUri = removeBaseUrl(baseData.refUri, tenantId, envId);
		}
		if (tenantName) {
			baseData.refUri = removeBaseUrl(baseData.refUri, tenantName, envId);
		}
	}

	if (baseData.name) {
		baseData.name = scrubSasToken(baseData.name);
	}

	if (baseData.target) {
		baseData.target = scrubSasToken(baseData.target);
	}

	return baseData;
};

let knownBaseUrls: string[] = null;
let hasLoadedEnvironmentUrls = false;

/**
 * appends DFP specific properties to the telemetryItem that will be sent to Application Insights
 * the appended properties will be nested under the customDimensions column
 */
export const dfpTelemetryInitializer = (envelope: ITelemetryItem) => {
	const environmentId = getEnvironmentIdFromTestHooks();
	const tenantId = getTenantId();
	const tenantName = getTenantName();
	const assignedRoles = getAssignedRolesFromTestHooks();
	const azureRoles = AuthHelper.wids;

	if (knownBaseUrls === null) {
		const spartaApiUrls = getSpartaApiUrls();

		knownBaseUrls = [
			...new Set([
				window.siteConfig.productionResourceUri,
				window.siteConfig.sandboxResourceUri,
				...(spartaApiUrls ? Object.values(spartaApiUrls) : []),
				...Object.entries(window.apiConfig)
					.filter(
						([_, apiConfig]) => apiConfig.BaseUri && !['{offline}', '{online}'].some((prefix) => apiConfig.BaseUri.startsWith(prefix)),
					)
					.map(([_, apiConfig]) => apiConfig.BaseUri),
			]),
		];
	}

	// the environmentConfig is retrieved through an graphql query to dfpprovisioning
	const environmentConfig = window.testHooks.stores.APP.environment;
	if (environmentConfig && !hasLoadedEnvironmentUrls) {
		knownBaseUrls.push(environmentConfig.offlineApiUrl, environmentConfig.onlineApiUrl);
		hasLoadedEnvironmentUrls = true;
	}

	if (envelope.baseData) {
		const baseData = scrubSensitiveDataFromBaseData(envelope.baseData, tenantId, tenantName, environmentId);

		/**
		 * automatic Ajax tracking is enabled in the appInsights configuration which will track all AJAX calls including the requests that we track
		 * manually via the trackOutgoingRequest method. so we want to filter out AJAX calls that are made to the list of known base URLs
		 * requests that are tracked via the trackOutgoingRequest method doesn't have a baseData.type value
		 */
		if (envelope.baseData.type === 'Ajax' && knownBaseUrls.some((url) => envelope.baseData.target.startsWith(url))) {
			return false;
		}

		if (environmentId || tenantId) {
			if (!baseData.properties) {
				baseData.properties = {};
			}
			if (environmentId) {
				baseData.properties[DFP_ENVID_HEADER] = environmentId;
			}
			if (tenantId) {
				baseData.properties[DFP_TENANTID_HEADER] = tenantId;
			}
			if (assignedRoles) {
				baseData.properties[DFP_ASSIGNEDROLES_HEADER] = assignedRoles;
			}
			if (azureRoles) {
				baseData.properties[AZUREROLES_HEADER] = azureRoles;
			}
			if (!baseData.properties[DFP_CORRID_HEADER]) {
				baseData.properties[DFP_CORRID_HEADER] = guid.generate();
			}
		}
	}
};
