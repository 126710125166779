import * as React from 'react';

import { ICustomProperties, SeverityLevel } from '@microsoft/applicationinsights-web';

import { Dictionary } from '../interfaces/Dictionary';
import * as guid from '../utilities/guid';
import { endMarker, getDurationForTask, startMarker } from './Performance';
import { AppInsights } from './appInsights';
import { DFP_CORRID_HEADER, END_MODIFIER_KEY, START_MODIFIER_KEY } from './constants';
import { EventType } from './models/EventType';
import { TelemetryId } from './telemetryConstants';
import { getEventProperties } from './utils';

export const trackTrace = (message: string, severityLevel: SeverityLevel, properties?: Dictionary<string>): void => {
	AppInsights.trackTrace({
		message,
		severityLevel,
		properties,
	});
};

export const trackException = (exception: Error, properties?: Dictionary<string>): void => {
	AppInsights.trackException({
		exception,
		severityLevel: SeverityLevel.Error,
		properties,
	});
};

export const trackEventStart = (id: string, eventName: string, eventType: EventType, properties?: Dictionary<string>): void => {
	startMarker(id);
	AppInsights.trackEvent({
		name: eventName,
		properties: { activityId: id, eventName, eventType, eventModifier: START_MODIFIER_KEY, ...properties },
	});
};

export const trackEventEnd = (id: string, eventName: string, eventType: EventType, properties?: Dictionary<string>): void => {
	endMarker(id);
	const eventDuration = getDurationForTask(id);

	AppInsights.trackEvent({
		name: eventName,
		properties: { activityId: id, eventName, eventType, eventModifier: END_MODIFIER_KEY, ...properties },
		measurements: { eventDuration },
	});
};

export const trackSingleEvent = (eventName: string, eventType: EventType, properties?: Dictionary<string>): void => {
	AppInsights.trackEvent({
		name: eventName,
		properties: { eventName, eventType, ...properties },
	});
};

/**
 * tracks a (async or synchronous) callback function to Application Insights
 *
 * @callbackFn the callback function to be executed. can be async or synchronous
 * @telemetryId the ID used to query this callback function execution in Application Insights
 * @customProperties any custom properties that should be sent to Application Insights can be an object or a callback function that returns an object. the callback function is useful for sending dynamic properties that are not available at the time of the callbackFn execution
 */
export const trackCallbackFn =
	(
		callbackFn: (...args: unknown[]) => void,
		telemetryId: TelemetryId | string,
		customProperties?: ICustomProperties | (() => ICustomProperties),
	): ((...args: unknown[]) => void) =>
	async (...args: unknown[]) => {
		let computedCustomProperties = customProperties;
		const changeEvent = args[0] as React.ChangeEvent;
		const htmlElement = changeEvent.currentTarget as HTMLElement;
		const name = telemetryId ? telemetryId : (changeEvent.currentTarget as HTMLElement).id;

		// if a callbackFn is provided, track the callbackFn duration
		if (callbackFn) {
			startMarker(name);
			await callbackFn(...args);
			endMarker(name);
		}

		if (typeof customProperties === 'function' && typeof customProperties.call === 'function') {
			computedCustomProperties = customProperties();
		}

		AppInsights.trackEvent({
			name,
			properties: {
				...getEventProperties(name, htmlElement),
				...(callbackFn && { eventDuration: getDurationForTask(name) }),
				...(computedCustomProperties && computedCustomProperties),
			},
		});
	};

export const trackOutgoingRequest = (
	name: string,
	absoluteUrl: string,
	duration: number,
	success: boolean,
	responseCode: number,
	correlationContext: string,
	retryCount: number,
	startDate: Date,
): void => {
	AppInsights.trackDependencyData({
		id: guid.generate(),
		name,
		duration,
		success,
		responseCode,
		correlationContext,
		target: absoluteUrl,
		properties: {
			[DFP_CORRID_HEADER]: correlationContext,
			retryCount,
		},
		startTime: startDate,
	});
};
