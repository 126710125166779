import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

import { dfpTelemetryInitializer } from './utils';

export const customDataPrefix = 'data-clk-';

// https://docs.microsoft.com/en-us/azure/azure-monitor/app/javascript#sending-telemetry-to-the-azure-portal
class DfpAppInsights {
	private appInsights: ApplicationInsights;

	public constructor() {
		const clickAnalyticsPluginInstance = new ClickAnalyticsPlugin();

		this.appInsights = new ApplicationInsights({
			config: {
				maxAjaxCallsPerView: -1,
				connectionString: window.siteConfig.appInsightsConnectionString,
				disableCookiesUsage: true,
				enableAutoRouteTracking: true,
				autoTrackPageVisitTime: true,
				extensions: [clickAnalyticsPluginInstance],
				extensionConfig: {
					// https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-feature-extensions?tabs=javascriptwebsdkloaderscript#add-advanced-configuration
					[clickAnalyticsPluginInstance.identifier]: {
						callback: {
							contentName: (element) => {
								const buttonTitle = element.getAttribute('title');
								const buttonAriaLabel = element.getAttribute('aria-label');
								if (buttonTitle) {
									return buttonTitle;
								} else if (buttonAriaLabel) {
									return buttonAriaLabel;
								}

								return element.innerText;
							},
						},
						dropInvalidEvents: true,
						dataTags: {
							customDataPrefix,
							useDefaultContentNameOrId: true,
						},
					},
				},
			},
		});
	}

	public initialize = () => {
		this.appInsights.loadAppInsights();
		this.appInsights.context.application.ver = `${__$buildNumber}`;

		this.appInsights.addTelemetryInitializer(dfpTelemetryInitializer);
	};

	public get instance() {
		return this.appInsights;
	}
}

const dfpAppInsights = new DfpAppInsights();

export const setupLogging = () => {
	dfpAppInsights.initialize();
};

export const AppInsights = dfpAppInsights.instance;
