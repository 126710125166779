export enum ProvisioningStatus {
	// The provisioning request has been received, but the environment is not yet being created.
	New = 'New',
	// The provisioning request has started for this environment.
	Creating = 'Creating',
	// The environment has been provisioned successfully.
	Active = 'Active',
	// Creating the environment has failed. See the error code for details.
	CreateFailed = 'CreateFailed',
	// Soft deleting the environment has failed. See the error code for details.
	SoftDeleteFailed = 'SoftDeleteFailed',
	// Another status for the error state.
	Error = 'Error',
	// The environment marked for soft delete
	SoftDeleting = 'SoftDeleting',
	// The provisioned resource has been deleted
	Deleted = 'Deleted',
	// Used by Pay as you go while resource is being deleted.
	Deleting = 'Deleting',
	// Deletion of Provisioned resource failed (e.g: PayG)
	DeleteFailed = 'DeleteFailed',
}
